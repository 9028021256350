import React, { FC } from 'react'
import { RouteComponentProps, NavLink } from 'react-router-dom'
import { Routes } from '../../AppRoutes'
import { Icon } from '../../components'

export interface ITransactionWraperProps extends RouteComponentProps {

}

export const withTransactionWraper = (Component: FC<ITransactionWraperProps>) => (props: ITransactionWraperProps) => {
    return (
        <div className="withTransactionWraper">
            <div className="TabLinks">
                <NavLink to={Routes.transactionList.path} exact={true}>
                    <Icon.Exchange />

                    Transactions
                </NavLink>

                <NavLink to={Routes.transactionDemoList.path} exact={true}>
                    <Icon.Exchange />

                    Transactions Demo
                </NavLink>

                <NavLink to={Routes.withdraw.path} exact={true}>
                    <Icon.KYC />

                    Withdraw Progress
                </NavLink>
            </div>

            <Component {...props} />
        </div>
    )
}