import Cookies from 'universal-cookie';
const cookies = new Cookies();

const DefaultConfigs = {
    'ENV': 'local',

    // General information
    'PUBLIC_URL': 'https://user-dev.fxpromax.com',

    // RESTful APIs
    'URL_API_MAIN_CLIENT_SIDE': 'https://api-dev-heroku.fxpromax.com',
    'URL_SOCKET': 'https://notifier.vonic.vn',
    'URL_SOCKET_APP_ID': 'LgpeFzIxtVfgtJ3L6',

    'GOOGLE_RECAPTCHA_KEY': '6Le0ev4UAAAAAEniDJf_d2ZaXPwv1AmMtKeS-lwA',
    'TRADE_REAL_COIN_CODE': 'USD',
    'TRADE_DEMO_COIN_CODE': 'DEMO',
}

export function getEnv(key:
    | 'ENV'

    // General information
    | 'PUBLIC_URL'

    // RESTful APIs
    | 'URL_API_MAIN_CLIENT_SIDE'
    | 'URL_SOCKET'
    | 'URL_SOCKET_APP_ID'

    | 'GOOGLE_RECAPTCHA_KEY'
    | 'TRADE_REAL_COIN_CODE'
    | 'TRADE_DEMO_COIN_CODE'
): string {
    return cookies.get(key) || DefaultConfigs[key]
}

export const isDev = getEnv('ENV') !== 'production';

export const CollectionDisplayOptions = [
    {
        label: 'Trang chủ',
        value: 'home',
    },
    {
        label: 'Dành cho người mới',
        value: 'new-member',
    },
    {
        label: 'Trang sản phẩm',
        value: 'product-page'
    }
]
