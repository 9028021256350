import React, { FC } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './main.scss';
import 'react-calendar/dist/Calendar.css';
import 'react-datetime-picker/dist/DateTimePicker.css';

import { PageAuthLogin } from './pages/auth';
import { withPageWraper, IPageProps } from './pages/wraper';
import { PageDashboard } from './pages/dashboard';
import { UserList } from './pages/user';
import { TransactionList } from './pages/transaction/transaction/TransactionList';
import { PageKYC } from './pages/user/kyc';
import { UserDetail } from './pages/user/user/UserDetail';
import { WithdrawProgress } from './pages/transaction/withdraw/WithdrawProgress';
import { PageUserKYCList } from './pages/user/kyc/KYCList';
import { PageUserAffiliation } from './pages/user/affiliation';
import { ReportSystem } from './pages/report/system';
import { PageFranchiseTree } from './pages/franchise/tree';
import { PageFranchiseTable } from './pages/franchise/table';
import { TransactionDemoList } from './pages/transaction/transaction/TransactionDemoList';
import { PageFranchiseLeaderTable } from './pages/franchise/leaderTable';

export const Routes = {
    dashboard: {
        path: '/',
        component: PageDashboard,
    },
    userList: {
        path: '/users',
        component: UserList,
    },
    userKYCList: {
        path: '/users/kycs',
        component: PageUserKYCList,
    },
    userAffiliation: {
        path: '/users/affiliation',
        component: PageUserAffiliation,
    },
    franchiseTable: {
        path: '/franchise',
        component: PageFranchiseTable,
    },
    franchiseTree: {
        path: '/franchise/tree',
        component: PageFranchiseTree,
    },
    franchiseLeader: {
        path: '/franchise/leader',
        component: PageFranchiseLeaderTable,
    },
    userDetail: {
        path: '/users/:email',
        component: UserDetail,
        renderPath: (email: string) => `/users/${email}`
    },
    transactionList: {
        path: '/transactions',
        component: TransactionList,
    },
    transactionDemoList: {
        path: '/transactions/demo',
        component: TransactionDemoList,
    },
    withdraw: {
        path: '/transactions/withdraws',
        component: WithdrawProgress,
    },
    kyc: {
        path: '/kyc',
        component: PageKYC,
    },
    reportSystem: {
        path: '/reports/system',
        component: ReportSystem,
    },
}

export const AppRoutes: FC = () => {
    return (
        <Router>
            <Switch>
                {Object.values(Routes).map((item: any, key) => {
                    return <Route
                        key={key}
                        exact
                        path={item.path}
                        component={withPageWraper((props: IPageProps) => {
                            return (
                                <item.component {...props} />
                            )
                        })}
                    />
                })}

                <Route path="/auth/login" component={PageAuthLogin} />
            </Switch>
        </Router>
    )
}