import React, { FC } from 'react'
import { UserService } from '../../services/user'
import { translate } from '../../languages'
import { Icon } from '../icon'
import { NavLink } from 'react-router-dom'
import { Routes } from '../../AppRoutes'

export const Navigator: FC = () => {
    return (
        <div className="Navigator">
            <img src="/assets/images/logo-symbol-white.png" alt="" className="logo" />
            <MenuLinks />
        </div>
    )
}

const MenuLinks: FC = () => {
    return <div className="menu">
        <NavLink exact to={Routes.dashboard.path}>
            <Icon.Dashboard />
            Dashboard
        </NavLink>

        <NavLink to={Routes.userList.path}>
            <Icon.User />
            Users
        </NavLink>

        <NavLink to={Routes.transactionList.path}>
            <Icon.Exchange />
            Transactions
        </NavLink>

        <NavLink to={Routes.franchiseTable.path}>
            <Icon.Affiliate />
            Franchise
        </NavLink>

        <NavLink to={Routes.reportSystem.path}>
            <Icon.Report />
            Reports
        </NavLink>

        <div className="btnLogout" onClick={() => UserService.logout()}>
            <Icon.Logout />
            {translate('logout')}
        </div>
    </div>
}